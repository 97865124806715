.App {
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
}

.row .col {
    display: flex;
    flex-direction: column;
}

a {
    color: var(--blue);
    text-decoration: none;
}

h1 {
    font-family: EvangelionRegular;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 3.5em;
    color: var(--blue);
}
h2 {
    font-family: EvangelionItalic;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 2.5em;
    color: var(--blue);
}

button {
    padding: 10px 20px;
    background-color: var(--blue);
    border: 1px solid var(--yellow);
    color: var(--yellow);
}

table {
    width: 100%;
}

table tr:nth-child(even) {
    background-color: var(--midnight);
}

table th {
    text-align: right;
}

table th.left {
    text-align: left;
}

table td {
    font-size: 1.4em;
    text-align: right;
}

table td.left {
    text-align: left;
}

table a {
    font-size: 1.6em;
}

.factions img {
    width: 50px;
    height: 50px;
}

.error {
    text-align: center;
    font-size: 1.3em;
    color: var(--yellow);
}

.LandsLoaded {
    font-size: 1.5em;
    color: var(--yellow);
    margin: 8px auto;
    text-align: center;
}
